<template>
  <div class="login-container">
    <div class="login-header">
      <div class="login-areaheart">
        <div class="login-logo">盛世康和</div>
        <div class="login-tel">400-697-0697</div>
      </div>
    </div>
    <div class="login-areaheart">
      <img src="@/assets/img/title.png" alt="" class="title-img">
      <div class="login-box" @keyup.enter="handleSubmit()">
        <p class="login-title">账号登录</p>
        <Form ref="loginForm" :model="loginForm" class="login-form">
          <Form-item>
            <Input type="text" v-model="loginForm.username" placeholder="员工工号"></Input>
          </Form-item>
          <Form-item>
            <Input type="password" v-model="loginForm.pwd" placeholder="密码"></Input>
          </Form-item>
          <Form-item>
            <Checkbox v-model="isAuto">下次自动登录</Checkbox>
          </Form-item>
          <Form-item>
            <Button type="primary" @click="handleSubmit()" long class="login-btn">登录</Button>
          </Form-item>
        </Form>
        <div class="error">{{errorMsg}}</div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import accountService from '@/services/accountService';
import { mapActions } from 'vuex';
export default {
	data() {
		return {
			loginForm: {
				username: '',
				pwd: '',
			},
			isAuto: false,
			errorMsg: '',
		};
	},
	methods: {
		...mapActions(['setUserInfo']),
		handleSubmit() {
			accountService
				.login(this.loginForm)
				.then((data) => {
					this.errorMsg = '';
					if (this.isAuto === true) {
						localStorage.setItem('autoLogin', 1);
					} else {
						localStorage.removeItem('autoLogin');
					}
					localStorage.setItem('isFirstLogin', 1);
					this.setUserInfo(data);
					localStorage.setItem('loginInfo', JSON.stringify(this.loginForm));
					localStorage.setItem('userPower', JSON.stringify(data.user_info.power));
					this.$router.push('/base/home');
				})
				.catch((data) => {
					this.errorMsg = data;
				});
		},
	},
};
</script>

<style lang="css">
.login-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(@/assets/img/bg.jpg);
  background-size: cover;
}

.login-header {
  height: 60px;
  background-color: rgba(50, 50, 58, .9);
}

.login-areaheart {
  width: 1120px;
  margin: 0 auto;
}

.login-logo {
  float: left;
  width: 158px;
  height: 60px;
  background: url(@/assets/img/logo_login.png) no-repeat center;
  overflow: hidden;
  text-indent: -9999em;
}

.login-tel {
  float: right;
  width: 188px;
  height: 60px;
  background: url(@/assets/img/phone_number.png) no-repeat center;
  overflow: hidden;
  text-indent: -9999em;
}

.title-img {
  float: left;
  margin-top: 70px;
  margin-left: -100px;
}

.login-box {
  float: right;
  margin-top: 180px;
  width: 350px;
  height: 330px;
  background-color: #fff;
  border-radius: 6px;
}

.login-title {
  height: 62px;
  line-height: 62px;
  text-align: center;
  font-size: 18px;
  border-bottom: 3px solid #f3f8ff;
}

.login-form {
  padding: 16px;
  background: url(@/assets/img/bg_input.png) no-repeat center 16px;
}

.login-form .ivu-form-item {
  margin-bottom: 0;
  margin-top: 2px;
}

.login-form .ivu-input {
  height: 40px;
  border: 0;
}

.login-form .ivu-input:focus {
  box-shadow: none;
}

.login-form .ivu-checkbox-wrapper {
  margin: 15px 0;
  color: #666;
}

.login-form .login-btn {
  height: 42px;
  font-size: 16px;
}

.login-container .error {
  padding-left: 18px;
  color: #f00;
}
</style>
